<template>
  <VueFinalModal @before-open="onBeforeOpen" name="pincode_unset">
    <ModalBase name="pincode_unset" titleIcon="alert" :title="$t('modal.profile.change_title')">
      <form @submit.prevent="onSubmit" class="modal-pincode">
        <div class="modal-pincode__text">{{ $t("modal.profile.change_text") }}</div>
        <VInput autocomplete="off" required v-model="secret" :placeholder="$t('modal.profile.set_secret')" />
        <div class="label-unset-text">{{ $t("modal.profile.change_label") }}</div>
        <PinInput v-model="code" required :characterPreview="false" secure class="modal-pincode__inputs" input-class="input" :length="4" autofocus placeholder="0" />
        <VButton type="submit" class="modal-pincode__btn" size="48"> {{ $t("modal.profile.change_submit") }} </VButton>
      </form>
    </ModalBase>
  </VueFinalModal>
</template>

<script setup lang="ts">
import { VueFinalModal, $vfm } from "vue-final-modal";
import { PinInput } from "v-pin-input";

const secret = ref("");
const code = ref("");
const isReadonly = ref(true);

function onBeforeOpen() {
  code.value = "";
  secret.value = "";
  isReadonly.value = false;
}

const { $axios, $auth } = useNuxtApp();

function onSubmit() {
  $axios
    .$post("/auth/changeCode", {
      secret: secret.value,
      code: code.value,
    })
    .then(({ response }) => {
      if (response?.notify?.type === "success") $vfm.hide("pincode_unset");
    });
}
</script>

<style lang="scss">
.label-unset-text {
  margin-bottom: 12px;
  margin-top: 20px;
  font-weight: 600;
  font-size: 14px;
}
.modal-pincode {
  width: 322px;
  max-width: 100%;
  color: var(--color_main__100);

  &__text {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 137.3%;
    margin-bottom: 20px;
  }

  &__btn {
    width: 100%;
    margin-top: 20px;
  }
}
</style>
